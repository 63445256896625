/* eslint-disable */
import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;

  return (
    <Layout
      headerTitle="Blog"
      location={location}
      mainLayout="px-4 py-12 mx-auto bg-white md:py-24 sm:px-6 lg:px-8"
      title="Blog"
    >
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <article
        className="mx-auto prose lg:prose-lg xl:prose-xl"
        itemScope
        itemType="http://schema.org/Article"
      >
        <h2 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          {post.frontmatter.title}
        </h2>
        <p className="mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
          Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat
          massa dictumst amet. Sapien tortor lacus arcu.
        </p>

        <section
          className="border-t-2 border-gray-100"
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr />
        <div className="flex justify-between w-full">
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              <button
                className={`${
                  previous === null ? `hidden` : `block`
                } inline-flex items-center px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-green-600 border border-transparent  hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 rounded-l `}
              >
                ← Previous Post
              </button>
            </Link>
          )}
          {next && (
            <Link to={next.fields.slug} rel="next">
              <button
                type="button"
                className={`${
                  next === null ? `hidden` : `block`
                } inline-flex items-center px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-green-600 border border-transparent hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 rounded-r`}
              >
                Next Post →
              </button>
            </Link>
          )}
        </div>
      </article>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`;
